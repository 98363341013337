/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Testimonial } from "src/templates"

import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "src/assets/styles/slick-cumplo-theme.css";

const Testimonials = ({title, items, autoPlay = false, variant = "1", backgroundImage = null}) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))
 const md = useMediaQuery(theme.breakpoints.down('md'))

 const itemsCount = items?.length
   
 const styles = {
  testimonialsBackground: {
   backgroundSize: 'cover',
   backgroundPosition: 'top center',
   minHeight: 545,
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   padding: "40px 0px",
   backgroundImage: variant === "2" ? `url(${backgroundImage})` : "none",
   "& .slick-dots li button:before": {
    border: variant === "2" ? `1px solid ${theme.palette.text.secondary} !important` :  `1px solid ${theme.palette.primary.main} !important`
   },
   "& .slick-dots li.slick-active button:before" : {
    background: variant === "2" ? `${theme.palette.text.secondary} !important` : `${theme.palette.primary.main} !important`,
    border: variant === "2" ? `1px solid ${theme.palette.text.secondary} !important` : `1px solid ${theme.palette.primary.main} !important`,
   }
  },
  testimonialsContainer: {
  },
  titleContainer: {
   padding: "40px 0",
  },
  carouselContainer: {
   width: '100%',
   maxWidth: itemsCount === 2 ? 1200 : 1920,
   paddingBottom: "80px",
  },
  fadeOutEffect: {
   display: md ? 'none' : 'block',
   width: 1920,
   height: 470,
   position: "absolute",
   marginTop: md ? 8 : '5%',
   pointerEvents: 'none',
   right: md ? "auto" : 0
  }
 }

 const settings = {
  arrows: false,
  draggable: lg ? true : (itemsCount <= 2 ? false : true),
  swipe: lg ? true : (itemsCount <= 2 ? false : true),
  dots: lg ? true : (itemsCount <= 2 ? false : true),
  infinite: lg ? true : (itemsCount <= 2 ? false : true),
  autoplay: autoPlay ? true : false,
  autoplaySpeed: 5000,
  variableWidth: true,
  rows: 1,
  dotsClass: 'slick-dots',
  responsive: [
   {
    breakpoint: 834,
    settings: {
     variableWidth: false,
     slidesToShow: 1,
    },
   },
  ],
 };
 
 return (
  <Box sx={styles.testimonialsBackground}>
   <Container sx={styles.testimonialsContainer}>
    <Box sx={styles.titleContainer}>
     <Typography variant="h3" align="center" color={ variant === "2" ? "text.secondary" : "primary.dark"} >{title}</Typography>
    </Box>
   </Container>
   <Box sx={styles.carouselContainer}>
    <Carousel {...settings}>
     {items?.map((testimonial, index) => (
      <Testimonial
       key={index}
       avatar={testimonial?.photo?.file?.url}
       author={testimonial?.name}
       position={testimonial?.position}
       quote={testimonial?.smallQuote}
       testimonial={testimonial?.longQuote?.internal?.content}
       variant = {variant}
      />
     ))}
    </Carousel>
   </Box>
   <Box sx={styles.fadeOutEffect}/>
  </Box>
 )}

export { Testimonials }