import React, { useEffect } from "react";
import { Introduction } from "src/components/Introduction";
import { ProductCards } from "src/components/common/ProductCards";
import { Hero, Indicator } from "src/templates";
import { Testimonials } from "src/components/common/Testimonials";
import { Solutions } from "src/components/common/Solutions";
import { Enterprises } from "src/components/common/Enterprises";
import PromoBanner from "src/components/PromoBanner";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Imports para el carrusel de empresas que forman parte de nuestra red en Chile

import TottusLogo from "src/assets/svg/logos/enterprises/tottus.svg";
import SoproleLogo from "src/assets/svg/logos/enterprises/soprole.svg";
import ParqueAraucoLogo from "src/assets/svg/logos/enterprises/parqueArauco.svg";
import MasisaLogo from "src/assets/svg/logos/enterprises/masisa.svg";
import ColbunLogo from "src/assets/svg/logos/enterprises/colbun.svg";
import AustralisLogo from "src/assets/svg/logos/enterprises/australis.svg";
import AesGenerLogo from "src/assets/svg/logos/enterprises/aesChile.svg";
import FalabellaLogo from "src/assets/svg/logos/enterprises/falabella.svg";
import EnelLogo from "src/assets/svg/logos/enterprises/enel.svg";

// Imports para el carrusel de empresas que forman parte de nuestra red en Mexico

import Velvet from "src/assets/images/logos/enterprises/VelvetSoda.png";
import GaiaLogo from "src/assets/svg/logos/enterprises/gaia.svg";
import MinisoLogo from "src/assets/svg/logos/enterprises/miniso.svg";
import YemaLogo from "src/assets/svg/logos/enterprises/Yema.svg";
import MicheMixLogo from "src/assets/svg/logos/enterprises/Michemix.svg";
import MarinaAzulLogo from "src/assets/svg/logos/enterprises/marinaAzul.svg";

// Imports para el Home de Chile

import simulator from "src/assets/svg/simulator-background.svg";

// Imports para los contenidos

import { getContent } from "src/queries";
import domainsJson from "../config/domainsEnv.json";
import { domain } from "cumplo_utils";
import { Paper } from "@cumplo/design-system";
import Banner from "../components/Banner/Banner";
import AnnouncementBar from "src/components/AnnouncementBar/AnnouncementBar";

export default function Home() {
  useEffect(() => {
    domain.setUp(domainsJson);
  }, []);

  const solutionsItems = [
    {
      title:
        "Simula tu anticipo y obtén financiamiento de forma rápida y simple",
      logo: null,
      subtitle:
        "Simula tu anticipo y obtén financiamiento de forma rápida y simple",
      description: "Regístrate y obtén tu pago hoy mismo.",
      solutionBackground: {
        width: "100%",
        backgroundImage: `url(${simulator})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
        borderRadius: "0% 0px 0% 0%",
      },
      features: [
        {
          icon: "checkLight",
          text: "Sin firmas de documentos ni responsabilidad de mora",
        },
        {
          icon: "moneyBag",
          text: "Sin comisiones ni costos adicionales",
        },
        {
          icon: "giveMoney",
          text: "Recibe tu dinero en pocas horas",
        },
      ],
      form: "ProntoPago",
      backgroundImage: simulator,
      backgroundImageMobile: simulator,
    },
  ];

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // Hero
  const heroHomeBannerResults = getContent(
    "hero-home-banner",
    process.env.GATSBY_REACT_APP_REGION
  );
  // "¿Cómo funciona el financiamiento colaborativo?"
  const purposeContentAreaResults = getContent(
    "purpose-content-area",
    process.env.GATSBY_REACT_APP_REGION
  );
  // Tarjetas de productos
  const productCardsFeatures = getContent(
    "product-cards-features",
    process.env.GATSBY_REACT_APP_REGION
  );
  // Empresas
  const enterprisesChileItems = [
    {
      logo: AesGenerLogo,
    },
    {
      logo: SoproleLogo,
    },
    {
      logo: TottusLogo,
    },
    {
      logo: ParqueAraucoLogo,
    },
    {
      logo: AustralisLogo,
    },
    {
      logo: ColbunLogo,
    },
    {
      logo: MasisaLogo,
    },
    {
      logo: FalabellaLogo,
    },
    {
      logo: EnelLogo,
    },
  ];
  const enterprisesMexicoItems = [
    {
      logo: Velvet,
    },
    {
      logo: GaiaLogo,
    },
    {
      logo: MinisoLogo,
    },
    {
      logo: YemaLogo,
    },
    {
      logo: MicheMixLogo,
    },
    {
      logo: MarinaAzulLogo,
    },
  ];
  // Testimoniales
  const testimonialsCarrouselResults = getContent(
    "testimonials-carrousel-home",
    process.env.GATSBY_REACT_APP_REGION
  );

  return (
    <>
      {/*{process.env.GATSBY_REACT_APP_REGION === "es-CL" && <AnnouncementBar />}*/}

      {/* Hero */}

      {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
        <PromoBanner
          bannerTitle="Tokenízate con Cumplo"
          bannerSubtitle="Rentable, líquido y simple"
          buttonText="descubre ahora"
          buttonLink="https://productos.cumplo.cl/cumplo-token"
        />
      )}

      <Hero
        title={heroHomeBannerResults?.title}
        body={heroHomeBannerResults?.body?.internal?.content}
        callToAction={heroHomeBannerResults?.callToAction}
        backgroundImagesDesktop={heroHomeBannerResults?.backgroundImagesDesktop}
        backgroundImagesMobile={heroHomeBannerResults?.backgroundImagesMobile}
        idCalltoAction="invertir_oportunidades_btn"
        marginTop={
          process.env.GATSBY_REACT_APP_REGION === "es-CL" ? "10px" : "59px"
        }
      />

      {/* "¿Cómo funciona el financiamiento colaborativo?" */}
      <Introduction
        title={purposeContentAreaResults?.title}
        imageLeft={purposeContentAreaResults?.images[0]?.file?.url}
        description={purposeContentAreaResults?.body?.internal?.content}
        callToAction={purposeContentAreaResults?.callToAction}
      />

      {/* Estadísticas */}
      <Container>
        <Grid
          container
          rowSpacing={md ? 2 : 0}
          justifyContent="space-evenly"
          sx={{
            position: "relative",
            bottom: 0,
            width: "100%",
            maxWidth: "840px",
            padding: "80px 0px 120px",
            margin: "0 auto",
          }}
        >
          <Grid item xs={6} md={3}>
            <Indicator
              icon="moneySuccess"
              stat="+US $2 600"
              label="Millones financiados"
              iconColor="secondary"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Indicator
              icon="enterprise"
              stat="+6 500"
              label="Pymes"
              iconColor="secondary"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Indicator
              icon="pieChart"
              stat="+200 000"
              label="Operaciones"
              iconColor="secondary"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Indicator
              icon="moneyDelete"
              stat="0,45%"
              label="Default"
              iconColor="secondary"
            />
          </Grid>
        </Grid>
      </Container>
      {/* Productos (1/2)*/}
      {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
        <ProductCards items={productCardsFeatures?.features.slice(0, 1)} />
      )}
      {/* Simulador */}
      {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
        <Solutions items={solutionsItems} />
      )}
      {/* Productos (2/2)*/}
      {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
        <ProductCards items={productCardsFeatures?.features.slice(1, 3)} />
      )}
      {/* Productos */}
      {process.env.GATSBY_REACT_APP_REGION !== "es-CL" &&
        process.env.GATSBY_REACT_APP_REGION !== "en-US" && (
          <ProductCards items={productCardsFeatures?.features} />
        )}
      {/* Empresas */}
      <Enterprises
        title={
          process.env.GATSBY_REACT_APP_REGION !== "en-US"
            ? "Son parte de nuestra red:"
            : "They are part of our network:"
        }
        items={
          process.env.GATSBY_REACT_APP_REGION === "es-CL"
            ? enterprisesChileItems
            : enterprisesMexicoItems
        }
      />
      {/* Testimoniales */}
      <Testimonials
        title={testimonialsCarrouselResults?.title}
        items={testimonialsCarrouselResults?.testimonials}
        autoPlay
      />
    </>
  );
}
