import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Button } from "src/templates"

const ProductCards = ({items}) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))
   
 const styles = {
  productsBackground: {
   background: `${theme.palette.primary.main}20`,
   padding: "55px 0px",
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
  },
  card: {
   width: "90%",
   maxWidth: "990px",
   margin: "25px",
   borderRadius: "20px",
  },
  container: {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   columnGap: "16px",
   padding: lg ? "32px" : "64px 0px",
   flexDirection: lg ? "column" : "row",
   textAlign: lg ? "center" : "auto"
  },
  containerReverse: {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   columnGap: "16px",
   padding: lg ? "32px" : "64px 0px",
   flexDirection: lg ? "column" : "row-reverse",
   textAlign: lg ? "center" : "auto"
  },
  image: {
   width: "100%",
   maxWidth: lg ? "128px" : "256px"
  },
  textsContainer: {
   display: "flex",
   flexDirection: "column",
   width: "100%",
   maxWidth: "496px",
   rowGap: "24px",
  }
 }
 
 return (
  <Box sx={styles.productsBackground}>
   {items.map((item, index) => (
    <Card sx={styles.card} key={index}>
     <Box sx={(index % 2) === 0 ? styles.container : styles.containerReverse}>
      <Box sx={styles.imageContainer}>
       <Box component="img" src={item?.image?.file?.url} sx={styles.image}/>
      </Box>
      <Box sx={styles.textsContainer}>
       <Box sx={styles.title}>
        <Typography variant="h3" color="primary.dark" display="inline">{`${item?.title} `}</Typography>
        <Typography variant="h3" color="primary.main" display="inline">{`${item?.description}`}</Typography>
       </Box>
       <Box sx={styles.description}>
        <Typography>{`${item?.body?.internal?.content}`}</Typography>
       </Box>
       <Box>
        <a href={item?.callToAction[0]?.link} style={{ textDecoration: 'none'}}>
         <Button variant="outlined">{`${item?.callToAction[0]?.text}`}</Button>
        </a>
       </Box>
      </Box>
     </Box>
    </Card>
   ))}
  </Box>
 )}

export { ProductCards }